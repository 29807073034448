<template>
  <div class="main">
    <div class="expired-password">
      <div class="expired-top">{{ $t('warmTips') }}</div>
      <div class="expired-content">
        <span v-html="$t('expirationTips1')"></span>
        <span v-html="$t('expirationTips2')"></span>
      </div>
      <div class="opearate-main">
        <aui-button type="danger" class="modify-password" @click="resetPwd">{{ $t('modifyNow') }}</aui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@aurora/vue'
import { getQueryValue, getUrlStr } from '@/utils/utils';

export default {
  components: {
    AuiButton: Button
  },
  methods: {
    resetPwd() {
      const { service, tenantId, urlType } = this.$route.query

      const query = { type: 1, tenantId, urlType, service: getQueryValue('service') }
      const queryString = getUrlStr(query);
      this.$router.push(`/resetPwd?${queryString}`)
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.main {
  .modify-password {
    background: #ea9fa3;
    border-color: #ea9fa3;
    color: #fff;
  }
}
.expired-password {
  margin: 80px auto 0 auto;
  width: 530px;
  padding: 60px 50px 80px 50px;
  background: #ffffff;
  .expired-top {
    text-align: center;
    font-size: 30px;
  }
  .expired-content {
    font-size: 14px;
    margin-top: 26px;
    padding: 0 10px;
    & > span {
      display: block;
    }
  }
  .opearate-main {
    text-align: center;
    margin-top: 30px;
    & > button {
      width: 100%;
      min-width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 767px) {
  .expired-password {
    width: 100%;
    padding: 50px 24px;
    .opearate-main {
      margin: 30px auto 0 auto;
      width: 100%;
      max-width: 100%;
      > button {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>